define("ember-initials/components/image/index", ["exports", "@ember/component", "@glimmer/tracking", "@ember/object", "@ember/application", "@ember/object/computed", "ember-initials/components/image/template"], function (_exports, _component, _tracking, _object, _application, _computed, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ImageAvatarComponent = (_dec = (0, _computed.reads)('size'), _dec2 = (0, _computed.reads)('size'), _dec3 = (0, _computed.reads)('config.image.defaultImageUrl'), (_class = class ImageAvatarComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "layout", _template.default);
      _defineProperty(this, "tagName", '');
      _initializerDefineProperty(this, "image", _descriptor, this);
      _initializerDefineProperty(this, "size", _descriptor2, this);
      _initializerDefineProperty(this, "alt", _descriptor3, this);
      _initializerDefineProperty(this, "title", _descriptor4, this);
      _initializerDefineProperty(this, "height", _descriptor5, this);
      _initializerDefineProperty(this, "width", _descriptor6, this);
      _initializerDefineProperty(this, "defaultImage", _descriptor7, this);
      _initializerDefineProperty(this, "_src", _descriptor8, this);
    }
    get src() {
      return this._src || this.image || this.defaultImage;
    }
    set src(value) {
      return this._src = value;
    }
    get config() {
      return (0, _application.getOwner)(this).resolveRegistration('config:environment').emberInitials;
    }
    onError(e) {
      if (this.defaultImage && this.image !== this.defaultImage) {
        e.srcElement.src = this.defaultImage;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "image", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "size", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 30;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "alt", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'User Avatar';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "title", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'User Avatar';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "height", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "width", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "defaultImage", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "_src", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onError", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onError"), _class.prototype)), _class));
  var _default = _exports.default = ImageAvatarComponent;
});